<template>
	<div class="notice_popup prj_itv state">
		<div class="close">
			<img src="/images/close.png" alt="닫기" @click="$emit('close')"/>
		</div>
		<div class="tit mb-12">프로젝트 &lsquo;승인&rsquo; 및 &lsquo;상태변경&rsquo; </div>
		
		<div class="board">
			<table class="Board_type2">
				<colgroup>
					<col width="150">
					<col width="*">
				</colgroup>
				<tbody>
					<tr>
						<th class="ivl01">프로젝트명</th>
						<td class="prj_name">{{input.projectNm}}</td>
					</tr>
					<tr>
						<th class="ivl19">
							<span class="required">*</span>
							승인여부
						</th>
						<td>
							<SelectComp type="radio" list="Y: 승인 ('프로젝트 찾기'에 노출),N: 미승인 ('프로젝트 찾기'에 노출안함)" v-model="input.apprYn" :disabled="input.apprYn=='Y'"/>
						</td>
					</tr>
				</tbody>
			</table>

			<div class="border-box">
				<table class="Board_type2">
					<colgroup>
						<col width="150">
						<col width="*">
					</colgroup>
					<tbody>
						<tr>
							<th class="ivl20">
								수주일
							</th>
							<td class="cont">
								<SelectDate tagNm="div" type="date" class="select-box" classY="wd02" classM="wd01" classD="wd01" from="Y-2" title="일" rules="required" v-model="input.contractYyyymmdd"/>
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
		
		<div class="ta-center" style="font-size: 19px;">
			위의 정보로 해당 프로젝트의 상태를 변경하시겠습니까?
		</div>

		<div class="select-btn">
			<div class="btn" @click="$emit('close')">취소</div>
			<div class="btn" @click="save()">예</div>
		</div>
		
	</div>
</template>

<script>

export default {
	props : {
		param : Object,	
	},
	data() {
		return {
			input : {
				projectSeq : this.param.projectSeq,
				projectNm : this.param.projectNm,
				apprYn : this.param.apprYn,
				contractYyyymmdd : this.param.contractYyyymmdd,
				projectDivCd : this.param.projectDivCd,
				parentProjectYn : this.param.parentProjectYn || '',
				beforeApprYn : this.param.apprYn,
			}
		}
	},

	mounted() {

	},

	methods : {
		save() {
			this.$.httpPost('/api/prj/adm/updateAppr', this.input)
			.then(() => {
				alert('수정되었습니다.')
				this.$emit('close', true);
			}).catch(this.$.httpErrorCommon);
		},
	}

}
</script>